<template>
  <div>
    <p-o-d-9-report></p-o-d-9-report>
  </div>
</template>

<script>
import POD9Report from "@/components/ecologist/reports/POD-9/POD-9Report";
export default {
  name: "POD-9View",
  components: { POD9Report },
};
</script>

<style scoped></style>
