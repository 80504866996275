<template>
  <div>
    <h2>ПОД-9</h2>
    <search-block title="отходов по тех процессу">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-select
            @change="changeBranch"
            clearable
            filterable
            class="searchPole"
            v-model="search.branch"
            value-key="id"
            placeholder="Филиал"
          >
            <el-option
              v-for="item in branchesList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            @change="changeSubDivision"
            :disabled="subDivisionsList.length === 0"
            clearable
            filterable
            class="searchPole"
            v-model="search.subDivision"
            value-key="id"
            placeholder="Подразделение"
          >
            <el-option
              v-for="item in subDivisionsList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            @change="changeDistrict"
            :disabled="districtsList.length === 0"
            clearable
            filterable
            class="searchPole"
            v-model="search.district"
            value-key="id"
            placeholder="Участок"
          >
            <el-option
              v-for="item in districtsList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            @change="changeTechProces"
            clearable
            filterable
            class="searchPole"
            v-model="search.techProcess"
            value-key="techProcessId"
            placeholder="Тех. процесс"
          >
            <el-option
              v-for="item in techProcessesList"
              :key="item.techProcessId"
              :label="item.techProcessName"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </search-block>

    <search-block
      title="отхода"
      v-if="techProcessesList.length != 0"
    >
      <el-form
        :model="reportForm"
        :rules="rules"
        ref="formedForm"
        label-position="top"
        label-width="120px"
      >
        <el-row :gutter="10">
          <el-form-item prop="startDate" label="Период">
            <el-col :span="12">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd"
                v-model="reportForm.startDate"
                type="date"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item prop="endDate" label="">
            <el-col :span="12">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd"
                v-model="reportForm.endDate"
                type="date"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row :gutter="10" v-if="search.techProcess !== null || search.techProcess === ''">
          <el-col :span="8">
            <el-form-item prop="selectedWaste" label="Наименование отхода">
              <el-select
                clearable
                filterable
                class="searchPole"
                v-model="reportForm.selectedWaste"
                value-key="wasteCode"
                placeholder="Наименование отхода"
              >
                <el-option
                  v-for="item in search.techProcess.wasteInfo"
                  :key="item.wasteCode"
                  :label="item.wasteName"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="Код отхода">
              <el-input
                v-model="reportForm.selectedWaste.wasteCode"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item
              prop="administrativeDocument"
              label="Номер распорядительного документа"
            >
              <el-select
                clearable
                class="searchPole"
                v-model="reportForm.administrativeDocument"
                filterable
                remote
                value-key="id"
                reserve-keyword
                placeholder="Номер документа"
                :remote-method="searchDocumentNumber"
              >
                <el-option
                  v-for="item in listDocumentNumbers"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item
              prop="responsiblePerson"
              label="Ответственный за ведение книги"
            >
              <el-select
                clearable
                class="searchPole"
                v-model="reportForm.responsiblePerson"
                value-key="id"
                placeholder="Ответственный за ведение книги"
              >
                <el-option
                  v-for="item in listResponsiblePersonForSelect"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="inspector" label="Проверил:">
              <el-select
                clearable
                class="searchPole"
                v-model="reportForm.inspector"
                value-key="personId"
                placeholder="Проверил"
              >
                <el-option
                  v-for="item in checkedPerson"
                  :key="item.personId"
                  :label="item.fullname"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" v-if="search.techProcess !== null || search.techProcess === ''">
            <el-form-item prop="fileType" label="Тип файла">
              <el-select
                clearable
                v-model="reportForm.fileType"
                placeholder="Формат отчета"
              >
                <el-option
                  v-for="item in fileTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="search.techProcess === null">
            <el-form-item prop="fileType" label="Тип файла">
              <el-select
                clearable
                v-model="reportForm.fileType"
                placeholder="Формат отчета"
              >
                <el-option
                  v-for="item in fileTypesxls"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-button @click="downloadReport" type="primary"
              >Сформировать отчет</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </search-block>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";

export default {
  name: "POD-9Report",
  components: { SearchBlock },
  data() {
    return {
      branchesList: [],
      subDivisionsList: [],
      districtsList: [],
      techProcessesList: [],
      techProcesses:[],
      search: {
        branch: {
          id: null,
          name: null,
        },
        subDivision: {
          id: null,
          name: null,
        },
        district: {
          id: null,
          name: null,
        },
        techProcess: null,
      },

      reportForm: {
        selectedWaste: {
          wasteInfo: {
            id: null,
            name: null,
          },
        },

        waste: null,
        responsiblePerson: null,
        organizationUnit: {
          id: null,
        },
        inspector: null,
        administrativeDocument: null,
        techProcessInfo: {
          id: null,
        },
        startDate: null,
        endDate: null,
        fileType: null,
      },

      fileTypes: [
        {
          value: ".pdf",
          label: ".pdf",
        },
        {
          value: ".xls",
          label: ".xls",
        },
      ],

      fileTypesxls: [
        {
          value: ".xls",
          label: ".xls",
        },
      ],

      rules: {
        startDate: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        endDate: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        selectedWaste: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        administrativeDocument: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        responsiblePerson: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        inspector: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        fileType: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      listDocumentNumbers: "GETLISTDOCUMENTNUMBERS",
      listAllTechProcessesForForm: "GETLISTALLTECHPROCESSESFORFORM",
      listResponsiblePersonForSelect: "GETLISTRESPONSIBLEPERSONFORSEARCH",
      organizationWaste: "GETORGANIZATIONWASTE",
      checkedPerson: "GETLISTCHECKEDPERSON",
    }),
  },

  methods: {
    changeBranch() {
      this.search.subDivision = {id: null,name: null,};
      this.search.district = {id: null,name: null,};
      this.search.techProcess = null;
      this.getUnitList(2, this.search.branch.id).then((response) => {
        this.subDivisionsList = response.data;
      });
      this.getUnitList(3, this.search.branch.id).then((response) => {
        this.districtsList = response.data;
      });
      this.getListResponsiblePersonForSelect();
      this.getTechProcessesList(this.search.branch.id);
    },

    changeSubDivision() {
      this.search.district = {id: null,name: null,};
      this.search.techProcess = null;
      this.getUnitList(3, this.search.subDivision.id).then((response) => {
        this.districtsList = response.data;
      });
      this.getListResponsiblePersonForSelect();
      this.getTechProcessesList(this.search.subDivision.id);
    },

    changeDistrict() {
      this.search.techProcess = null;
      this.getListResponsiblePersonForSelect();
      this.getTechProcessesList(this.search.district.id);
    },

    changeTechProces() {
      //this.search.techProcess.wasteInfo.push({wasteCode: 9999999999, wasteName: 'Все'});
    },

    getTechProcessesList(unitId) {
      this.$store
        .dispatch("techProcessForSelect", { unitId: unitId })
        .then((response) => {
          this.techProcesses = [];
          this.techProcessesList = response.data;
          response.data.forEach(techProcesslist=> 
            techProcesslist.techProcessHasWasteInfo.forEach(techprozes=>
            this.techProcesses.push(techprozes)
            )
          );
          console.log(this);
        });
    },

    selectWaste() {
      this.$store.dispatch(
        "getOrganizationWaste",
        this.reportForm.selectedWaste.organizationWasteId
      );
    },

    searchDocumentNumber(query) {
      this.$store.dispatch("getDocsNumbersSearch", { query: query });
    },

    getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 3,
      });
    },

    getListResponsiblePersonForSelect() {
      let units = [];
      if (this.search.branch.id !== null) {
        units.push(this.search.branch.id);
      }
      if (this.search.district.id !== null) {
        units.push(this.search.district.id);
      }
      if (this.search.subDivision.id !== null) {
        units.push(this.search.subDivision.id);
      }
      this.$store.dispatch("getListResponsiblePersonForSelect", units);
    },

    downloadReport() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          console.log(this.search);
          let techProcessHasWasteId;
          if (this.search.techProcess!=null)
          techProcessHasWasteId =
            this.search.techProcess.techProcessHasWasteInfo[
              this.search.techProcess.wasteInfo.findIndex(
                (item) =>
                  item.ss01DictionaryId ===
                  this.reportForm.selectedWaste.ss01DictionaryId
              )
            ];
          let path = "";
          if (this.search.branch.name !== null) {
            path += this.search.branch.name;
          }

          if (this.search.subDivision.name !== null) {
            path += " / " + this.search.subDivision.name;
          }

          if (this.search.district.name !== null) {
            path += " / " + this.search.district.name;
          }

          

          let data = {
            unitPath: path,
            techProcessHasWasteId: techProcessHasWasteId,
            startDate: this.reportForm.startDate,
            endDate: this.reportForm.endDate,
            administrativeDocumentId: this.reportForm.administrativeDocument.id,
            responsiblePersonId: this.reportForm.responsiblePerson.id,
            personId: this.reportForm.inspector.personId,
            fileType: this.reportForm.fileType,
            techProcessesId: this.techProcesses
          };
          //if (this.search.techProcess!=null)
          //data.techProcessHasWasteId = techProcessHasWasteId;

          this.$store.dispatch("downloadPod9Report", {data, reportName:"POD-9"});
        }
      });
    },

    async getUnitList(unitType, unitId) {
      return await this.$store.dispatch("organizationUnitsForSelect", {
        unitType: unitType,
        unitId: unitId,
      });
    },

    getListCheckedPerson() {
      this.$store.dispatch("getCheckedPersonForSelect");
    }
  },

  mounted() {
    this.getUnitList(1, 0).then((response) => {
      this.branchesList = response.data;
    });
    this.getUnitList(2, 0).then((response) => {
      this.subDivisionsList = response.data;
    });
    this.getUnitList(3, 0).then((response) => {
      this.districtsList = response.data;
    });
    this.getTechProcessesList(0);
    this.getListResponsiblePersonForSelect(-1);
    this.searchDocumentNumber("");
    this.getListCheckedPerson();
    this.$store.dispatch("getListAllTechProcessesForForm");
  },
};
</script>

<style scoped></style>
